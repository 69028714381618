import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL

// const googleApiUrl = "https://maps.googleapis.com/maps/api/place/autocomplete/json?";
const getAuthToken = () => document.cookie.split("; ").find(row => row.startsWith("chota="))?.split("=")[1];

const errorHandler = (error) => {
  console.error("API Request Error:", error);
  return {
    error: "An error occurred while processing your request.",
    response: error.response || null,
  };
};

const createAxiosInstance = (apiPath) => {
  const token = getAuthToken();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  return axios.create({
    baseURL: apiPath,
    headers: headers,
  });
};

const createRequestFunction = (instance) => {
  return (method, path, data) => {
    return instance[method](path, data)
      .then(response => ({ data: response.data, response }))
      .catch(error => errorHandler(error));
  };
};

const general = (apiPath) => {
  const instance = createAxiosInstance(apiPath);
  const requestFunction = createRequestFunction(instance);

  return {
    getJson: (path, data) => requestFunction("get", path, { params: data }),
    postJson: (path, data) => requestFunction("post", path, data),
    putJson: (path, data) => requestFunction("put", path, data),
    deleteJson: (path, data) => requestFunction("delete", path, { params: data }),
  };
};

export const Auth = () => general(`${baseUrl}/auth`);
export const Activation = () => general(`${baseUrl}/activations`);
export const User = () => general(`${baseUrl}/users`);

// export const Property = () => general(`${baseUrl}/properties`);
// export const Quote = () => general(`${baseUrl}/quotes`);
// export const Utils = () => general(`${baseUrl}/utils`);
