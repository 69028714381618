import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { format } from "date-fns";
import { formatDateTime } from "../../utils/formattingUtils";
import { useNavigate } from "react-router-dom";

const ActivationCard = ({ activation, onClick }) => {
  const { churchName, region, freq, mode, startTime, endTime } = activation;
  const callsign = activation.userId.callsign;
  const navigate = useNavigate();

  const goToUser = () => {
    navigate(`/userProfile/${activation.userId.callsign}`)
  }

  return (
    <Card onClick={onClick} sx={{ marginBottom: 2, margin: "10px", maxWidth: 300 }} key={activation._id}>
      <CardContent>
        <Grid container spacing={1}>
        <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Activator:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" onClick={goToUser} sx={{ cursor: 'pointer'}}>{callsign}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Church:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{churchName}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Region:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{region}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Frequency (mode):</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{freq} ({mode})</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Start Time:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{formatDateTime(startTime)}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">End Time:</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2">{formatDateTime(endTime)}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ActivationCard;
