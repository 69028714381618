import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";
import { Activation } from "../utils/httpUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatISO } from 'date-fns';

const ActivationModal = ({
  open,
  onClose,
  updateActivations,
  displaySuccessAlert,
}) => {
  const [churchName, setChurchName] = useState("");
  const [freq, setFreq] = useState("");
  const [mode, setMode] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [region, setRegion] = useState("");

  const createActivation = () => {
    const toUTC = (date) => {
      if (!date) return '';
      const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return formatISO(utcDate, { representation: 'complete' });
    };

    const data = {
      churchName,
      freq,
      mode,
      startTime: toUTC(startTime),
      endTime: toUTC(endTime),
      region,
    };

    Activation()
      .postJson("createActivation", data)
      .then((response) => {
        if (response.response.status === 200) {
          updateActivations();
          displaySuccessAlert();
          onClose();
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ userSelect: "none" }}>Activate Church</DialogTitle>
      <DialogContent sx={{ overflowY: 'auto', paddingBottom: '300px' }}>
        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item xs={7}>
            <TextField
              label="Church Name"
              value={churchName}
              onChange={(event) => setChurchName(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Region"
              value={region}
              onChange={(event) => setRegion(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Frequency"
              value={freq}
              onChange={(event) => setFreq(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Mode"
              value={mode}
              onChange={(event) => setMode(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              dateFormat="yyyy-MM-dd HH:mm"
              timeIntervals={15}
              placeholderText="Select start time"
              customInput={<TextField fullWidth label="Start Time (UTC)" />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              dateFormat="yyyy-MM-dd HH:mm"
              timeIntervals={15}
              placeholderText="Select end time"
              customInput={<TextField fullWidth label="End Time (UTC)" />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <RedButton onClick={onClose} variant="contained" color="primary">
          Close
        </RedButton>
        <GreenButton
          onClick={createActivation}
          variant="contained"
          color="primary"
        >
          Submit
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActivationModal;
