import React, { useState } from "react";
import { GreenButton } from "../../components/reusableComponents/Buttons";
import { Typography, Grid, Snackbar, Alert, CircularProgress } from "@mui/material";
import ActivationModal from "../../modals/ActivationModal";
import EditActivationModal from "../../modals/EditActivationModal";
import MyActivationCard from "../../components/cards/MyActivationCard";

function MyActivationsDesktop({ myActivations, updateActivations, isLoading }) {
  const [displayActivationModal, setDisplayActivationModal] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayEditActivationModal, setDisplayEditActivationModal] = useState(false);
  const [selectedActivation, setSelectedActivation] = useState();

  const openEditModal = () => {
    setDisplayEditActivationModal(true);
  };
  const closeEditModal = () => {
    setDisplayEditActivationModal(false);
  };

  const openActivationModal = () => {
    setDisplayActivationModal(true);
  };

  const closeActivationModal = () => {
    setDisplayActivationModal(false);
  };

  const displaySuccessAlert = () => {
    setDisplaySuccess(true);
  };

  const editActivation = (activation) => {
    setSelectedActivation(activation);
    openEditModal();
  };

  return (
    <>
      <ActivationModal
        open={displayActivationModal}
        onClose={closeActivationModal}
        updateActivations={updateActivations}
        displaySuccessAlert={displaySuccessAlert}
      />
      <EditActivationModal
        open={displayEditActivationModal}
        onClose={closeEditModal}
        activation={selectedActivation}
        updateActivations={updateActivations}
        displaySuccessAlert={displaySuccessAlert}
      />
      <Snackbar
        open={displaySuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setDisplaySuccess(false)}
      >
        <Alert
          onClose={() => setDisplaySuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Activation successfully updated!
        </Alert>
      </Snackbar>

      {isLoading ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <GreenButton onClick={openActivationModal} sx={{ marginTop: "50px" }}>
            Create New Activation
          </GreenButton>
          {myActivations.length === 0 ? (
            <Typography sx={{ marginTop: "50px" }}>
              You have no activations
            </Typography>
          ) : (
            <>
              <Typography sx={{ marginTop: "50px" }}>
                Click Activation to edit
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                {myActivations.map((activation, index) => (
                  <Grid item key={index} style={{ margin: "10px" }}>
                    <MyActivationCard
                      activation={activation}
                      onClick={() => editActivation(activation)}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

export default MyActivationsDesktop;
