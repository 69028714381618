import React, { useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import NotFound from "./pages/NotFound";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import UserProfile from "./pages/UserProfile";
import Activations from "./pages/Activations";
import Profile from "./pages/Profile";

const theme = createTheme({
  palette: {
    primary: {
      // main: "#444444",
      main: "#252F3D",
      light: "#FF8369",
      dark: "#14181f",
      contrastText: "white",
    },
    secondary: {
      main: "#4f8f2f",
      light: "#00FF00",
      dark: "#006600",
      contrastText: "white",
    },
    green: {
      main: "#4f8f2f",
      dark: "#396627",
      contrastText: "white",
    },
    red: {
      main: "#bf0423",
      dark: "#6e0113",
      contrastText: "white",
    },
    greenButton: {
      main: "#4f8f2f",
      dark: "#396627",
      contrastText: "white",
      fontFamily: 'Roboto, sans-serif', // Override button font specifically if needed
      fontWeight: 'bold', // Example of setting font weight to bold
      fontSize: "15px",
      borderRadius: "20px"
    }
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    // fontFamily: "Helvetica, sans-serif",
  },
  tableHeader: {
    main: "#252F3D",
    fontFamily: 'Roboto, sans-serif', // Override button font specifically if needed
    fontWeight: 'bold', // Example of setting font weight to bold
    fontSize: "20px"
  }
});

export { theme };

//#006600

function App() {

  return (
    <>
      <ThemeProvider theme={theme}>
              <Router>
                <div className="App">
                  {
                    <Routes>
                      <Route path="/" element={<Landing />}></Route>
                      <Route path="/register" element={<Register />}></Route>
                      <Route path="/login" element={<Login />}></Route>
                      <Route path="/dashboard" element={<Dashboard />}></Route>
                      <Route path="/activations" element={<Activations />}></Route>
                      {/* userProfile is a different user's profile page, not the currently logged in users profile page */}
                      <Route path="/userProfile/:callsign" element={<UserProfile />}></Route>
                      <Route path="/profile" element={<Profile />}></Route>
                      {/* <Route path="/login" element={<Login />}></Route> */}
                      {/* <Route path="/login" element={<Login />}></Route> */}
                     
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  }
                </div>
              </Router>
      </ThemeProvider>
    </>
  );
}

export default App;