import React, { useState, useEffect, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

import useMediaQuery from "@mui/material/useMediaQuery";

function CommonNavbar() {
  // const { user } = useContext(UserContext);
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [pages, setPages] = useState([]);
  const [accountSubPages, setAccountSubPages] = useState([]);

  const [userRole, setUserRole] = useState(localStorage.getItem('userRole'))

  

  useEffect(() => {
    setPages(["login", "register"]);
  }, []);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handleClickSubMenu = (event) => {
    setAnchorElSubMenu(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    setAnchorElSubMenu(null);
  };

  const logout = () => {
    document.cookie =
      "token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; SameSite=Lax;";
  };

  return (
    <AppBar
      position="static"
      sx={{ marginBottom: "70px", marginLeft: 0, marginRight: 0 }}
    >
      <Container maxWidth="100%">
        <Toolbar disableGutters sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {/* <img
            src={"/assets/logo.jpeg"}
            alt="Logo"
            style={{ height: "50px", marginRight: "20px" }}
          /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: "flex",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: "32px",
            }}
          >
            CHOTA
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {pages?.map((page, index) => (
              <React.Fragment key={index}>
                {page === "account" ? (
                  <>
                    <Button
                      key={index}
                      onClick={handleClickSubMenu}
                      sx={{
                        my: 2,
                        color: location.pathname.startsWith("/account")
                          ? "#47a123"
                          : "white",
                        fontSize: "25px",
                        fontWeight: "500",
                      }}
                    >
                      {page}
                    </Button>

                    <Menu
                      anchorEl={anchorElSubMenu}
                      open={Boolean(anchorElSubMenu)}
                      onClose={handleCloseSubMenu}
                    >
                      {accountSubPages?.map((subPage, subIndex) => (
                        <MenuItem key={subIndex} onClick={handleCloseSubMenu}>
                          <Link
                            to={`/account/${subPage}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            {subPage}
                          </Link>
                        </MenuItem>
                      ))}
                      <MenuItem>
                        <Link
                          to={`/`}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          onClick={() => {
                            logout();
                            toggleDrawer(false);
                          }}
                        >
                          logout
                        </Link>
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Button
                    key={index}
                    onClick={toggleDrawer(false)}
                    sx={{
                      my: 2,
                      color:
                        location.pathname === `/${page}` ? "#47a123" : "white",
                      fontSize: "25px",
                      fontWeight: "500",
                    }}
                    component={Link}
                    to={`/${page}`}
                  >
                    {page}
                  </Button>
                )}
              </React.Fragment>
            ))}
          </Box>
          {/* {!isMobile && (
            <IconButton
              size="large"
              sx={{ fontSize: "30px" }}
              edge="end"
              color="inherit"
              component={Link}
              to="/settings"
            >
              <SettingsIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          )} */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <List>
          {pages?.map((page, index) => (
            <ListItem key={index} button onClick={toggleDrawer(false)}>
              <Link
                to={`/${page}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                {page}
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
}

export default CommonNavbar;
