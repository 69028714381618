import React, { useState, useEffect, useContext } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  isValidEmail,
  validateEmptyFields,
  validatePassword,
} from "../../utils/validationUtils";
import { CustomTextField } from "../../components/reusableComponents/Inputs";
import { GreenButton } from "../../components/reusableComponents/Buttons";
import { Auth } from "../../utils/httpUtils";
import CommonNavbar from "../../components/reusableComponents/CommonNavbar";

const Register = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [callsign, setCallsign] = useState("");
  const [isSelected, setIsSelected] = useState([]);
  const [error, setError] = useState([]);

  const [chotaJwt, setChotaJwt] = useState(
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("chota="))
      ?.split("=")[1]
  );
  const [created, setCreated] = useState(false);

  useEffect(() => {
    if (created) {
      evaluateAndRedirect();
    }
  }, [created]);

//   useEffect(() => {
//     if (document.cookie)
//   })

  const evaluateAndRedirect = () => {
    navigate("/dashboard");
  };

  const register = async () => {
    const inputs = {
      firstName,
      lastName,
      callsign,
      email,
      password,
    };
    const emptyFields = validateEmptyFields(inputs);

    if (emptyFields.length > 0) {

      setIsSelected(emptyFields);
      setError(["Please fill in all fields."]);
      return;
    }

    if (!isValidEmail(email)) {
      setIsSelected(["email"]);
      setError(["Invalid email"]);
      return;
    }

    if (!validatePassword(password)) {
      setIsSelected(["password"]);
      setError(["Password must be at least 8 characters long."]);
      return;
    }

    const data = {
      firstName,
      lastName,
      password,
      email,
      callsign
    };

    Auth()
      .postJson("register", data)
      .then((response) => {
        if (response.response.data.errors) {
          handleError(response.response.data.errors);
          return;
        }
        if (response.data?.token) {
          setCreated(true);
          document.cookie = `chota=${response.data.token}; path=/; secure; SameSite=Lax;`;
        }
      });
  };

  const handleError = (errors) => {
    const errorMessages = errors.map((error) => error.msg);
    const errorFields = errors.map((error) => error.field);
    setError(errorMessages);
    setIsSelected(errorFields);
  };

  return (
    <div>
        <CommonNavbar />
      <div className={"createAccountContainer"}>
        <Container
          sx={{
            paddingTop: 5,
            paddingHorizontal: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mb={3}>
            {/* <img
              src={"assets/logo.jpeg"}
              alt="Logo"
              style={{ width: 300, height: 300 }}
            /> */}
            <Typography>Register</Typography>
          </Box>

          {/* First Name */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="First Name (optional)"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("firstName")}
            />
          </Box>

          {/* Last Name */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Last Name (optional)"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("lastName")}
            />
          </Box>

          {/* Company Name */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Callsign"
              variant="outlined"
              value={callsign}
              onChange={(e) => setCallsign(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("callSign")}
            />
          </Box>

          {/* Email */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("email")}
            />
          </Box>

          {/* Password */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("password")}
            />
          </Box>

          <GreenButton
            variant="contained"
            onClick={register}
            sx={{ marginBottom: 2 }}
          >
            Create Account
          </GreenButton>

          <Box sx={{ marginTop: 2 }}>
            {error.length > 0 &&
              error.map((err, index) => (
                <Typography key={index} color="error" sx={{ fontSize: 14 }}>
                  {err}
                </Typography>
              ))}
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Register;
