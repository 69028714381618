import React, { useState, useEffect } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import MyActivationsDesktop from "./desktop/MyActivationsDesktop";
import MyActivationsMobile from "./mobile/MyActivationsMobile";

import { Activation } from "../utils/httpUtils";

function MyActivations() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [myActivations, setMyActivations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!myActivations) {
      getMyActivations();
    }
  });

  const getMyActivations = () => {
    Activation()
      .getJson("getMyActivations")
      .then((response) => {
        if (response.response.status === 200) {
          setMyActivations(response.data.myActivations);
          setIsLoading(false)
        }
      });
  };

  return (
    <>
      {isMobile ? (
        <MyActivationsMobile
          myActivations={myActivations}
          updateActivations={getMyActivations}
          isLoading={isLoading}
        />
      ) : (
        <MyActivationsDesktop
          myActivations={myActivations}
          updateActivations={getMyActivations}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default MyActivations;
