import React from 'react'
import CommonNavbar from '../components/reusableComponents/CommonNavbar'
import { Typography } from '@mui/material'

function Landing() {
  return (
    <>
    <CommonNavbar />
    <Typography sx={{fontSize: "30px"}}>Welcome to Churches On The Air (CHOTA)! Thank you to John G3XYF for starting this whole thing! </Typography>
    <Typography sx={{fontSize: "20px"}}>For information on the "official" CHOTA and it's founding organization WACRAL, click <a href="https://wacral.org/">here</a> </Typography>
    
    
    </>
    )
}

export default Landing