import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { User } from '../../utils/httpUtils';
import UserNavbar from '../../components/reusableComponents/UserNavbar';

function ProfileDesktop({ user }) {

    // useEffect(() => {
    //     if (!user) {
    //         getUser()
    //     }
    // })

//   useEffect(() => {
//     getUserInfo()
//   }, [callsign])

//   const getUserInfo = () => {
//     const data = {
//         callsign
//     }
//     User().getJson("getUserProfile", data)
//     .then(response => {
//         if (response.response.status === 200) {
//             setUser(response.data.user)
//         }
//     })
//   }

const determineName = () => {
    if (!user) {
        return null
    }
    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`
    } 
    if (user.firstName) {
        return `${user.firstName}`
    }
    else {
        return `no name`
    }
}

  return (
    <div style={{minWidth: "100%"}}>
      <h1>Name: {determineName()}</h1>
      <h1>Callsign: {user?.callsign}</h1>
      <h1>QRZ: {user?.qrz}</h1>

    </div>
  );
}

export default ProfileDesktop;
