function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    // Extract UTC values
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Format as YYYY-MM-DD HH:MM UTC
    return `${year}-${month}-${day} ${hours}:${minutes} UTC`;
}

exports.formatDateTime = formatDateTime;

function convertToUTC(dateTimeString) {
    const date = new Date(dateTimeString);

    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Format the date and time as MM/DD/YYYY HH:MM UTC
    // let formattedDateTime = `${month}/${day}/${year} ${hours}:${minutes}`;
    return `${year}-${month}-${day}T${hours}:${minutes}`;
    // return formattedDateTime;
}

exports.convertToUTC = convertToUTC;
