import React from 'react';

import useMediaQuery from "@mui/material/useMediaQuery";
import ProfileDesktop from './desktop/ProfileDesktop';
import ProfileMobile from "./mobile/ProfileMobile";


//UserProfile is another users profile page; not the currently logged in user's profile page
function UserProfile() {
    const isMobile = useMediaQuery("(max-width:600px)");

  return <>{isMobile ? <ProfileMobile /> : <ProfileDesktop />}</>;
}

export default UserProfile;