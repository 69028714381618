import React, { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../components/reusableComponents/Inputs";
import { GreenButton } from "../components/reusableComponents/Buttons";

import { Auth } from "../utils/httpUtils";
import CommonNavbar from "../components/reusableComponents/CommonNavbar";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSelected, setIsSelected] = useState([]);
  const [error, setError] = useState([]);

  const login = async () => {
    const data = {
      email,
      password,
    };
    Auth()
      .postJson("login", data)
      .then((response) => {
        if (response.response?.status === 200) {
          if (response.data.token) {
            document.cookie = `chota=${response.data.token}; path=/; secure; SameSite=Lax;`;
            navigate("/activations");
          }
        } else {
          console.log(response.response.data.message)
        }
      });
  };

  return (
    <div>
      <div className={"loginContainer"}>
        <CommonNavbar />
        <Container
          sx={{
            paddingTop: 5,
            paddingHorizontal: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mb={3}>
            {/* <img
              src={"assets/logo.jpeg"}
              alt="Logo"
              style={{ width: 300, height: 300 }}
            /> */}
            <Typography>Login</Typography>
          </Box>

          {/* Email */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("email")}
            />
          </Box>

          {/* Password */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("password")}
            />
          </Box>

          <GreenButton
            variant="contained"
            onClick={login}
            sx={{ marginBottom: 2 }}
          >
            Submit
          </GreenButton>

          <Box sx={{ marginTop: 2 }}>
            {error.length > 0 &&
              error.map((err, index) => (
                <Typography key={index} color="error" sx={{ fontSize: 14 }}>
                  {err}
                </Typography>
              ))}
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Login;
