import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import ActivationsDesktop from "./desktop/ActivationsDesktop";
import ActivationsMobile from "./mobile/ActivationsMobile";

import { Auth, Activation } from "../utils/httpUtils";

function Activations() {
  const [user, setUser] = useState(null);
  const [activations, setActivations] = useState([]);

  useEffect(() => {
    getUser();
    getActivations();
  }, []);

  const getUser = () => {
    Auth()
      .getJson("getUser")
      .then((response) => {
        if (response.data.user) {
          setUser(response.data.user);
        }
      });
  };

  const getActivations = () => {
    Activation()
      .getJson("getActivations")
      .then((response) => {
        if (response.data.activations) {
            setActivations(response.data.activations);
        }
      });
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>
      {isMobile ? (
        <ActivationsMobile user={user} activations={activations} updateActivations={getActivations} />
      ) : (
        <ActivationsDesktop user={user} activations={activations} updateActivations={getActivations}/>
      )}
    </>
  );
}

export default Activations;
