import React, { useState } from "react";
import { Typography, Grid, Snackbar, Alert } from "@mui/material";
import { GreenButton } from "../../components/reusableComponents/Buttons";
import ActivationModal from "../../modals/ActivationModal";
import ActivationCard from "../../components/cards/ActivationCard";
import UserNavbar from "../../components/reusableComponents/UserNavbar";

function ActivationsDesktop({ user, activations, updateActivations }) {
  const [displayActivationModal, setDisplayActivationModal] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const openActivationModal = () => {
    setDisplayActivationModal(true);
  };

  const closeActivationModal = () => {
    setDisplayActivationModal(false);
  };

  const displaySuccessAlert = () => {
    setDisplaySuccess(true);
  };

  return (
    <div style={{ position: "relative" }}>
      <ActivationModal
        open={displayActivationModal}
        onClose={closeActivationModal}
        updateActivations={updateActivations}
        displaySuccessAlert={displaySuccessAlert}
      />
      <Snackbar
        open={displaySuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setDisplaySuccess(false)}
      >
        <Alert
          onClose={() => setDisplaySuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Activation successfully created!
        </Alert>
      </Snackbar>
      <UserNavbar />
      {user ? (
        <>
          <Typography sx={{ marginBottom: "40px" }}>
            Welcome back, {user.callsign}!
          </Typography>
          {!activations || activations.length === 0 ? (
            <>
              <div style={{ marginBottom: "20px" }}>there aren't any current activations</div>
              <GreenButton onClick={openActivationModal}>
                Create Activation
              </GreenButton>
            </>
          ) : (
            <>
              <Typography sx={{ marginBottom: "40px" }}>
                Scheduled Activations
              </Typography>
              <GreenButton onClick={openActivationModal}>
                Create Activation
              </GreenButton>
              <Grid container spacing={2} justifyContent="center">
                {activations.map((activation, index) => (
                  <Grid item key={index} style={{ margin: "10px" }}>
                    <ActivationCard activation={activation} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      ) : (
        <div>loading</div>
      )}
    </div>
  );
}

export default ActivationsDesktop;
