import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SideNavbar from "../../components/reusableComponents/SideNavbar";
import ProfileDesktop from "./ProfileDesktop";
import MyActivations from "../MyActivations";

import UserNavbar from "../../components/reusableComponents/UserNavbar";

function DashboardDesktop({ user }) {
  const [selectedOption, setSelectedOption] = useState("Profile");
  const options = [
    "Profile",
    "My Activations"
  ];

  const renderComponent = () => {
    switch (selectedOption) {
      case "Profile":
        return (
          <ProfileDesktop
          user={user}
          />
        );

      case "My Activations":
        return <MyActivations />;

     

      default:
        return (
          <ProfileDesktop
          user={user}
          />
        );
    }
  };

  return (
    <div>
      <UserNavbar />
      <Grid container style={{ minHeight: "100vh"  }}>
        <Grid item xs={1.7}>
          <SideNavbar options={options} setSelectedOption={setSelectedOption} />
        </Grid>
        <Grid item xs={10}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12}>
              <div id="dashboard">{renderComponent()}</div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardDesktop;
