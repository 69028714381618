import React, { useState, useEffect } from 'react';

import useMediaQuery from "@mui/material/useMediaQuery";
import ProfileDesktop from './desktop/ProfileDesktop';
import ProfileMobile from "./mobile/ProfileMobile";

import { Auth } from '../utils/httpUtils';

function Profile() {
    const isMobile = useMediaQuery("(max-width:600px)");
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!user) {
            getUser()
        }
    })

    const getUser = () => {
        Auth().getJson("getUser")
        .then(response => {
            if (response.status === 200) {
                setUser(response.data.user)
            } else {
                setError("Error retrieving user information")
            }
        })
    }



  return <>{isMobile ? <ProfileMobile user={user}/> : <ProfileDesktop user={user} />}</>;
}

export default Profile;