import React from 'react';

import useMediaQuery from "@mui/material/useMediaQuery";

import RegisterDesktop from './desktop/RegisterDesktop';
import RegisterMobile from './mobile/RegisterMobile';

function Register() {
    const isMobile = useMediaQuery("(max-width:600px)");

  return <>{isMobile ? <RegisterMobile /> : <RegisterDesktop />}</>;
}

export default Register